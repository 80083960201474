<template>
  <div>
    <tab-bar-wrapper/>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :contextMenuItems="contextMenuItems"
        :columns="columns"
        :query="query"
        :filter="filter"
        :annotations="annotations"
        :nested-query="nestedQuery"
        :nested-filters="nestedFilters"
        :nested-annotations="nestedAnnotations"
        :componentName="$options.name"
        :nested-grid="true"
        :nestedColumns="nestedColumns"
        :nestedContextMenuItems="nestedContextMenuItems"
        :is-row-master-function="rowMasterFunction"
        @ready="ready"
        @nestedReady="nestedReady"
        @setComments="setComments"
        @cell-key-down="gridKbEventSender"
      />
    </div>
  </div>
</template>

<script>
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import grid from '@/components/mixins/grid'
import columns from '@/components/mixins/columns'
import { GRID_GET_PURCHASE_RMAS as PRMA } from '@/api/graphql/Constants/RMAs'
import mutateRMAs from '@/api/graphql/mutationsJS/mutateRMAs'
import { GRID_GET_PURCHASE_RMA_ITEMS } from '@/api/graphql/Constants/RMAs'
import { COLUMNS__PURCHASE_RMAS, COLUMNS__PURCHASE_RMAS_NESTED } from '@/lib/agGridColumnConfiguration'
import {
  CONTEXT_MENU__PURCHASES_RMAS,
  CONTEXT_MENU__PURCHASES_RMAS_NESTED
} from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__PURCHASES_RMA, KB_SHORTCUT__PURCHASES_RMA_NESTED } from '@/lib/agGridKbShortCuts'
import { RowMasterFunctionForItemCountAnnotation } from '@/lib/helpers'
export default {
  name: 'prma',
  mixins: [grid, columns, mutateRMAs],
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    // 'action-bar-wrapper': ActionBarWrapper,
    'grid-wrapper': GridWrapper
  },
  data () {
    return {
      contextMenuItems: params => CONTEXT_MENU__PURCHASES_RMAS(params),
      nestedContextMenuItems: (params) => CONTEXT_MENU__PURCHASES_RMAS_NESTED(params),

      commentList: {
        'internal_comment': {
          name: 'internal_comment',
          label: 'Internal',
          text: '',
          editable: true,
          show: true
        },
        'contract_comment': {
          name: 'contract_comment',
          label: 'Contract',
          text: '',
          editable: true,
          show: true
        },
        'id': false
      },

      // apollo stuff
      query: PRMA,
      filter: [],
      annotations: [
        {
          name: 'itemCount',
          aggr_type: 'COUNT',
          aggr_field: 'purchase_rma_items'
        }
      ],

      nestedQuery: GRID_GET_PURCHASE_RMA_ITEMS,
      nestedFilters: (id) => [{ key: 'rma__id', value: id }],
      nestedAnnotations: [],

      columns: COLUMNS__PURCHASE_RMAS,

      nestedColumns: COLUMNS__PURCHASE_RMAS_NESTED
    }
  },
  methods: {
    rowMasterFunction (data) {
      return RowMasterFunctionForItemCountAnnotation(data.annotations)
    },

    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__PURCHASES_RMA(), KB_SHORTCUT__PURCHASES_RMA_NESTED())
    },

    async placeInNextStatus (id, status) {
      try {
        await this.updatePurchaseRmaStatus(id, status)
        this.$store.dispatch('data/changeRefresh', { bool: true }, { root: true })
      } catch (error) {
      }
    }
  }
}
</script>
